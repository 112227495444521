<template>
  <div class="modal fade" tabindex="-10" id="modal_add_pagamento">
    <div class="modal-dialog">
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Introduci Pagamento</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="bg-light-danger text-danger p-6 rounded text-start mb-5 mx-5"
                >
                  <b>ATTENZIONE:</b> si ricorda di allegare nell'area
                  Documentale i documenti relativi ai pagamenti effettuati.
                </div>
                <div
                  class="alert alert-danger p-6 rounded text-start mb-5 mx-5"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold">Stagione*</label>
                      <SelectInput
                        :options="years"
                        name="anno"
                        placeholder="Seleziona"
                        :value="anno"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold">Importo*</label>
                      <div class="input-group mb-3 input-group-sm">
                        <Field
                          name="importo"
                          type="text"
                          v-model="importo"
                          class="form-control"
                          @change="setImporto($event.target.value)"
                        />
                        <span class="input-group-text">€</span>
                      </div>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="importo"
                      />
                    </div>
                    <div class="col-md-5">
                      <label class="fw-bold">Tipo pagamento*</label>
                      <Field
                        name="id_Tipo_Pagamento"
                        as="select"
                        class="form-select"
                        v-model="id_Tipo_Pagamento"
                        @change="setPagamentoTipo($event)"
                      >
                        <option value="00" selected>Seleziona</option>
                        <option
                          v-for="pagamento in pagamento_tipo_uso_economato_societa"
                          :key="pagamento.id"
                          :value="pagamento.id"
                          :id="pagamento.dati_agg"
                        >
                          {{ pagamento.label }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Tipo_Pagamento"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="pagDatiAggiuntivi">
                    <div class="col-md-4">
                      <label class="fw-bold">Data pagamento*</label>
                      <Datepicker
                        v-model="data_Competenza"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        locale="it"
                        :maxDate="new Date()"
                        @update:model-value="
                          data_Competenza = fromUTCtoISODate($event)
                        "
                      />
                      <Field
                        name="data_Competenza"
                        type="hidden"
                        class="form-control"
                        v-model="data_Competenza"
                      />
                      <ErrorMessage name="data_Competenza" style="color: red" />
                    </div>
                    <div class="col-md-8">
                      <label class="fw-bold">Banca / Agenzia*</label>
                      <Field
                        name="banca"
                        type="text"
                        v-model="banca"
                        class="form-control"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="banca"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="pagDatiAggiuntivi">
                    <div class="col-md-12">
                      <label class="fw-bold">Riferimenti*</label>
                      <Field
                        name="riferimento"
                        type="text"
                        class="form-control"
                        v-model="riferimento"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="riferimento"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalPagamentoSoc"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Registra
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import TextInput from "../../utility/inputs/TextInput.vue";
import SelectInput from "../../utility/inputs/SelectInput.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import { useStore } from "vuex";

import { addPagamento } from "@/requests/economato";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "pagamenti-societa-add",
  emits: ["refreshList", "setSpinnerView"],
  props: ["societa"],
  components: {
    //TextInput,
    SelectInput,
    Form,
    Datepicker,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const pagamento_tipo_uso_economato_societa = computed(() =>
      store.getters.getStateFromName("pagamento_tipo_uso_economato_societa")
    );

    if (!pagamento_tipo_uso_economato_societa.value) {
      store.dispatch("setStoreData", {
        apiLink: globalApi.LOOKUP_TIPO_PAGAMENTI_SOCIETA,
      });
    }

    const alertCampiObb = ref(false);
    const importo = ref(null);
    const id_Tipo_Pagamento = ref("00");
    const pagDatiAggiuntivi = ref(1);
    const data_Competenza = ref(null);
    const banca = ref("");
    const riferimento = ref("");
    const anno = ref(null);

    const setPagamentoTipo = (e) => {
      if (
        e.target.selectedOptions[0]._value != null &&
        String(e.target.selectedOptions[0]._value) != ""
      ) {
        id_Tipo_Pagamento.value = e.target.selectedOptions[0]._value;
        if (
          pagDatiAggiuntivi.value == 1 &&
          parseInt(e.target.selectedOptions[0].id) == 0
        ) {
          data_Competenza.value = null;
          banca.value = "";
          riferimento.value = "";
        }
        pagDatiAggiuntivi.value = parseInt(e.target.selectedOptions[0].id);
      } else {
        id_Tipo_Pagamento.value = "00";
        pagDatiAggiuntivi.value = 1;
        data_Competenza.value = null;
        banca.value = "";
        riferimento.value = "";
      }
    };

    const setImporto = (e) => {
      importo.value = e;
    };

    const schema = yup.object().shape({
      anno: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      importo: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      id_Tipo_Pagamento: yup
        .string()
        .test("notEmpty", "Obbligatorio", function (value) {
          if (value != "00" && value != "") {
            return true;
          } else {
            return false;
          }
        }),
      data_Competenza: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      /*.nullable()
        .test("notEmpty2", "Obbligatorio", function (value) {
          if (parseInt(pagDatiAggiuntivi.value) == 1) {
            return false;
          } else {
            return true;
          }
        }),*/
      banca: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      riferimento: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
    });

    const disableButton = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      Swal.fire({
        html:
          "Per confermare il pagamento di EUR " +
          String(Number(values.importo).toFixed(2)) +
          ", si prega di cliccare su <b>Conferma</b>.<br />Per annullare l'operazione cliccare su <b>Annulla</b>.",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          disableButton.value = true;
          emit("setSpinnerView", true);
          values.id_societa = props.societa.id;
          values.descrizione = banca.value + " - " + riferimento.value;
          const responseAdd = addPagamento(values);
          responseAdd
            .then((value) => {
              if (value.data.payment_id) {
                pagamento(value.data.payment_id);
                return;
              }
              if (value.data.statusCode == 200) {
                Swal.fire({
                  html: "Operazione completata",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                emit("refreshList");
                document.getElementById("closeModalPagamentoSoc").click();
                resetField();
              } else {
                const strMsg = ref(
                  value.data.message
                    ? value.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                Swal.fire({
                  html: strMsg.value,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
              disableButton.value = false;
            })
            .finally(() => {
              emit("setSpinnerView", false);
            });
        }
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      anno.value = null;
      setImporto(null);
      id_Tipo_Pagamento.value = "00";
      pagDatiAggiuntivi.value = 1;
      data_Competenza.value = null;
      banca.value = "";
      riferimento.value = "";
    };

    const axeptaClient = window.axeptaClient;
    const pagamento = (payment_id) => {
      axeptaClient.proceedToPayment(`${payment_id}`);
    };

    const years = computed(() =>
      store.getters.getStateFromName("affiliazioni_stagioni_aperte")
    );

    const keys = ref("afp");
    {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      onSubmit,
      onInvalidSubmit,
      schema,
      pagamento_tipo_uso_economato_societa,
      id_Tipo_Pagamento,
      setPagamentoTipo,
      pagDatiAggiuntivi,
      format,
      fromUTCtoISODate,
      data_Competenza,
      banca,
      riferimento,
      alertCampiObb,
      resetField,
      anno,
      disableButton,
      setImporto,
      importo,
      years,
    };
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.3rem 0.75rem;
}
</style>
