<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getEconomatoSocietaList')"
      >
        <div class="row pb-4">
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="dataRegistDaEconomatoSoc"
              placeholder="Data registrazione da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              @update:modelValue="setDataRegistDaEconomatoSoc($event)"
            />
          </div>
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="dataRegistAEconomatoSoc"
              placeholder="Data registrazione a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              @update:modelValue="setDataRegistAEconomatoSoc($event)"
            />
          </div>
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="dataApprovDaEconomatoSoc"
              placeholder="Data approvazione da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              @update:modelValue="setDataApprovDaEconomatoSoc($event)"
            />
          </div>
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="dataApprovAEconomatoSoc"
              placeholder="Data approvazione a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              @update:modelValue="setDataApprovAEconomatoSoc($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              placeholder="Tipo"
              aria-label=""
              :value="tipoEconomatoSoc"
              @input="setTipoEconomatoSoc"
            />
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              placeholder="Causale"
              aria-label=""
              :value="causaleEconomatoSoc"
              @input="setCausaleEconomatoSoc"
            />
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              placeholder="Descrizione"
              aria-label=""
              :value="descrizioneEconomatoSoc"
              @input="setDescrizioneEconomatoSoc"
            />
          </div>
          <div class="col-sm-3">
            <SelectInput
              :options="statoApprovLookup"
              name="statoApprovLookup"
              placeholder="Stato approvazione"
              :value="statoApprovazioneEconomatoSoc"
              @changeSelect="setStatoApprovazioneEconomatoSoc($event)"
            />
          </div>
        </div>
      </div>
      <div class="text-center mb-5">
        <button
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="badge rounded-pill bg-light text-gray-600 ms-4"
          @click="$emit('getEconomatoSocietaList')"
          :disabled="!loaded"
        >
          Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
        </button>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="badge rounded-pill bg-light text-gray-600 ms-4"
          @click="$emit('resetFilters')"
        >
          <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { useFormatDateYMD, format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtriEconomatoSocieta",
  emits: ["resetFilters", "getEconomatoSocietaList"],
  props: {},
  components: {
    SelectInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const store = useStore();

    const causaleEconomatoSoc = computed(
      () => store.getters.causaleEconomatoSoc
    );
    const tipoEconomatoSoc = computed(() => store.getters.tipoEconomatoSoc);
    const descrizioneEconomatoSoc = computed(
      () => store.getters.descrizioneEconomatoSoc
    );
    const statoApprovazioneEconomatoSoc = computed(
      () => store.getters.statoApprovazioneEconomatoSoc
    );
    const dataRegistDaEconomatoSoc = computed(
      () => store.getters.dataRegistDaEconomatoSoc
    );
    const dataRegistAEconomatoSoc = computed(
      () => store.getters.dataRegistAEconomatoSoc
    );
    const dataApprovDaEconomatoSoc = computed(
      () => store.getters.dataApprovDaEconomatoSoc
    );
    const dataApprovAEconomatoSoc = computed(
      () => store.getters.dataApprovAEconomatoSoc
    );

    const setCausaleEconomatoSoc = (e) => {
      const string = e.target.value;
      store.commit("setCausaleEconomatoSoc", string);
    };
    const setTipoEconomatoSoc = (e) => {
      const string = e.target.value;
      store.commit("setTipoEconomatoSoc", string);
    };
    const setDescrizioneEconomatoSoc = (e) => {
      const string = e.target.value;
      store.commit("setDescrizioneEconomatoSoc", string);
    };
    const setStatoApprovazioneEconomatoSoc = (event) => {
      store.commit("setStatoApprovazioneEconomatoSoc", event);
    };
    const setDataRegistDaEconomatoSoc = (event) => {
      store.commit("setDataRegistDaEconomatoSoc", event);
    };
    const setDataRegistAEconomatoSoc = (event) => {
      store.commit("setDataRegistAEconomatoSoc", event);
    };
    const setDataApprovDaEconomatoSoc = (event) => {
      store.commit("setDataApprovDaEconomatoSoc", event);
    };
    const setDataApprovAEconomatoSoc = (event) => {
      store.commit("setDataApprovAEconomatoSoc", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getEconomatoSocietaList");
    });

    return {
      causaleEconomatoSoc,
      statoApprovazioneEconomatoSoc,
      dataRegistDaEconomatoSoc,
      dataRegistAEconomatoSoc,
      dataApprovDaEconomatoSoc,
      dataApprovAEconomatoSoc,
      tipoEconomatoSoc,
      descrizioneEconomatoSoc,
      setCausaleEconomatoSoc,
      setTipoEconomatoSoc,
      setDescrizioneEconomatoSoc,
      setStatoApprovazioneEconomatoSoc,
      setDataRegistDaEconomatoSoc,
      setDataRegistAEconomatoSoc,
      setDataApprovDaEconomatoSoc,
      setDataApprovAEconomatoSoc,
      useFormatDateYMD,
      format,
      statoApprovLookup: [
        { id: true, label: "Approvato" },
        { id: false, label: "Non approvato" },
      ],
      loaded: computed(() =>
        store.getters.getStateFromName("loadedente_economato_list")
      ),
    };
  },
});
</script>

<style></style>
