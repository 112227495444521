<template>
  <div>
    <FiltriEconomatoSocieta
      @getEconomatoEnteList="searchEconomatoSocList"
      @resetFilters="resetFilters"
    />
    <TableEconomatoSocieta
      @getEconomatoEnteList="getEconomatoEnteList"
      @resetFilters="resetFilters"
      :societa="ente"
      :launchSearch="launchSearch"
      :reset="reset"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriEconomatoSocieta from "@/components/components-fit/enti-aggregati/economato/FiltriEconomatoSocieta.vue";
import TableEconomatoSocieta from "@/components/components-fit/enti-aggregati/economato/TableEconomatoSocieta.vue";

export default defineComponent({
  name: "economatoEnte",
  components: {
    FiltriEconomatoSocieta,
    TableEconomatoSocieta,
  },
  props: {
    ente: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato Ente", [
        "Lista Ente",
        "Dettaglio Ente",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipEconomatoEnte);
    const fetchRows = computed(() => store.getters.fetchRowsEconomatoEnte);
    const sortColumn = computed(() => store.getters.sortColumnEconomatoEnte);
    const sortOrder = computed(() => store.getters.sortOrderEconomatoEnte);

    const tipo_mov = computed(() => store.getters.tipoEconomatoEnte);
    const causale_nuova = computed(() => store.getters.causaleEconomatoEnte);
    const descrizione = computed(() => store.getters.descrizioneEconomatoEnte);
    const approvato = computed(
      () => store.getters.statoApprovazioneEconomatoEnte
    );
    const data_da = computed(() => store.getters.dataRegistDaEconomatoEnte);
    const data_a = computed(() => store.getters.dataRegistAEconomatoEnte);
    const data_appr_da = computed(
      () => store.getters.dataApprovDaEconomatoEnte
    );
    const data_appr_a = computed(() => store.getters.dataApprovAEconomatoEnte);
    const launchSearch = ref(0);

    const getEconomatoEnteList = () => {
      launchSearch.value++;
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.ente.id,
          stagione: stagione.value,
          tipo_mov: trimInput(tipo_mov.value),
          causale_mov: trimInput(causale_nuova.value),
          descrizione: descrizione.value,
          approvato: approvato.value,
          data_da: data_da.value,
          data_a: data_a.value,
          data_appr_da: data_appr_da.value,
          data_appr_a: data_appr_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_ECONOMATO_LIST,
        itemName: "ente_economato_list",
      });
    };
    getEconomatoEnteList();

    const reset = ref(false);
    const resetFilters = () => {
      store.commit("resetFiltersEconomatoEnte");
      getEconomatoEnteList();
      reset.value = true;
      setTimeout(() => {
        reset.value = false;
      }, 1000);
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedente_economato_list")
    );

    const searchEconomatoEnteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipEconomatoEnte");
      getEconomatoEnteList();
      reset.value = true;
      setTimeout(() => {
        reset.value = false;
      }, 1000);
    };

    return {
      getEconomatoEnteList,
      resetFilters,
      searchEconomatoEnteList,
      launchSearch,
      reset,
    };
  },
});
</script>
