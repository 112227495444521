<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_sposta_crediti"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sposta Crediti</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetValues()"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row">
                <div class="col-sm-4">
                  <label class="fw-bold">Dalla stagione*</label>
                  <SelectInput
                    :options="stagioni"
                    name="stagione"
                    :value="stagioneFrom"
                    @changeSelect="setStagioneFrom($event)"
                  />
                </div>
                <div class="col-sm-1 text-center align-self-center">
                  <label class="fw-bold text-white">-</label>
                  <span>
                    <i class="bi bi-arrow-left-right fw-bold fs-4"></i>
                  </span>
                </div>
                <div class="col-sm-4">
                  <label class="fw-bold">Alla stagione*</label>
                  <SelectInput
                    :options="stagioni"
                    name="stagione"
                    :value="stagioneTo"
                    @changeSelect="setStagioneTo($event)"
                    :disabled="
                      isLoadingRiepilogo || !stagioneFrom || viewSaldoMsg
                    "
                  />
                </div>
                <div class="col-sm-3">
                  <label class="fw-bold"
                    >Importo*<Popper
                      arrow
                      content=" di default viene mostrato il massimo importo trasferibile."
                    >
                      <i
                        class="bi bi-info-circle-fill text-center ps-2"
                      ></i> </Popper
                  ></label>
                  <div class="input-group mb-0 input-group-sm">
                    <input
                      type="text"
                      class="form-control"
                      :value="importo"
                      @input="setImporto($event)"
                      :disabled="
                        isLoadingRiepilogo || !stagioneFrom || viewSaldoMsg
                      "
                    />

                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </div>
              <div
                class="alert alert-danger mt-4"
                role="alert"
                v-if="viewSaldoMsg"
              >
                Non è disponibile nessun credito per la stagione selezionata.
              </div>
              <div
                class="alert alert-danger mt-4"
                role="alert"
                v-if="msgVirgola"
              >
                Non è possibile inserire ",", sostituire la virgola con un
                punto.
              </div>
              <div
                class="alert alert-danger mt-4"
                role="alert"
                v-if="msgImportoSup"
              >
                L'importo non può superare il saldo di €
                {{ importo_trasferibile }}
              </div>
              <div
                class="alert alert-danger mt-4"
                role="alert"
                v-if="
                  (!stagioneTo || !stagioneFrom || !importo) &&
                  viewErrorMsg &&
                  !viewSaldoMsg &&
                  !msgVirgola &&
                  !msgImportoSup
                "
              >
                Compilare tutti i campi
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalSpostaCrediti"
            @click="resetValues()"
          >
            Chiudi
          </button>
          <button
            type="submit"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="confermaSpostaCrediti()"
            :disabled="viewSaldoMsg || msgVirgola || msgImportoSup"
          >
            Sposta
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";

import SelectInput from "../../utility/inputs/SelectInput.vue";
import { useStore } from "vuex";

import Popper from "vue3-popper";
import { spostaCrediti, getRiepilogoEconomato } from "@/requests/economato";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "sposta-Crediti",
  emits: ["getDetailsEconomato"],
  props: ["societa"],
  components: {
    Popper,
    SelectInput,
    Loading,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagioniTutte = computed(() => store.getters.stagioni);

    const stagioneTo = ref(null);
    const stagioneFrom = ref(null);

    const stagioni = ref([]);
    const importo = ref(null);

    /*  const stagioni_to = ref([]);
    const stagioni_from = ref([]); */

    /* stagioni.value.forEach((element) => {
      if (element.id >= new Date().getFullYear()) {
        stagioni_to.value.push(element);
      } else {
        stagioni_from.value.push(element);
      }
    }); */

    stagioniTutte.value.forEach((element) => {
      if (element.id >= 2022) {
        stagioni.value.push(element);
      }
    });

    const isLoading = ref(false);
    const viewErrorMsg = ref(false);
    const viewSaldoMsg = ref(false);
    const msgVirgola = ref(false);
    const msgImportoSup = ref(false);
    const isLoadingRiepilogo = ref(false);
    const importo_trasferibile = ref(null);

    const setImportoTrasferibile = (stagione) => {
      stagioneTo.value = null;
      importo.value = null;
      viewErrorMsg.value = false;
      viewErrorMsg.value = false;
      msgImportoSup.value = false;
      msgVirgola.value = false;
      isLoadingRiepilogo.value = true;
      getRiepilogoEconomato(props.societa.id, stagione).then((res) => {
        isLoadingRiepilogo.value = false;
        if (res.results[0].saldo > 0) {
          importo.value = res.results[0].saldo;
          importo_trasferibile.value = res.results[0].saldo;
        } else importo.value = null;
        if (!importo.value || importo.value <= 0) viewSaldoMsg.value = true;
      });
    };

    const setStagioneFrom = (event) => {
      stagioneFrom.value = event;
      viewSaldoMsg.value = false;
      if (stagioneFrom.value) setImportoTrasferibile(event);
    };
    const setStagioneTo = (event) => {
      stagioneTo.value = event;
    };
    const setImporto = (event) => {
      msgImportoSup.value = false;
      msgVirgola.value = false;
      if (event.target.value.includes(",")) {
        msgVirgola.value = true;
      }
      if (!msgVirgola.value && !msgImportoSup.value) {
        event.target.value =
          event.target.value.indexOf(".") >= 0
            ? event.target.value.substr(0, event.target.value.indexOf(".")) +
              event.target.value.substr(event.target.value.indexOf("."), 3)
            : event.target.value;
        importo.value =
          event.target.value != ""
            ? parseFloat(parseFloat(event.target.value).toFixed(2))
            : null;
      }
      if (importo.value > parseFloat(importo_trasferibile.value)) {
        msgImportoSup.value = true;
      }
    };

    const stagioneCorrente = computed(() => store.getters.stagioneSelected);

    const confermaSpostaCrediti = () => {
      if (stagioneFrom.value && stagioneTo.value && importo.value) {
        Swal.fire({
          title: "Sei Sicuro?",
          html:
            "il credito di €" +
            importo.value +
            " verrà spostato alla stagione " +
            stagioneTo.value,
          icon: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Annulla",
          confirmButtonText: "Sposta credito",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
            cancelButton: "btn fw-bold btn-light-danger",
          },
        }).then(async (e) => {
          if (e.isDismissed) {
            return;
          }
          if (e.isConfirmed) {
            isLoading.value = true;
            spostaCrediti(
              props.societa.id,
              stagioneFrom.value,
              stagioneTo.value,
              importo.value
            ).then((res) => {
              isLoading.value = false;

              if (res.data.statusCode == 200) {
                alertSuccess("Credito spostato correttamente");
                document.getElementById("closeModalSpostaCrediti").click();
                emit(
                  "getDetailsEconomato",
                  props.societa.id,
                  stagioneCorrente.value
                );
                resetValues();
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
            });
          }
        });
      } else {
        viewErrorMsg.value = true;
      }
    };

    const resetValues = () => {
      stagioneFrom.value = null;
      stagioneTo.value = null;
      importo.value = null;
      msgImportoSup.value = false;
      msgVirgola.value = false;
      viewErrorMsg.value = false;
      viewSaldoMsg.value = false;
    };

    return {
      stagioneTo,
      stagioneFrom,
      /* stagioni_to,
      stagioni_from, */
      importo,
      stagioni,
      isLoading,
      isLoadingRiepilogo,
      viewErrorMsg,
      confermaSpostaCrediti,
      setStagioneFrom,
      setStagioneTo,
      setImporto,
      viewSaldoMsg,
      msgVirgola,
      importo_trasferibile,
      msgImportoSup,
      resetValues,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

.modal-body {
  padding: 0;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.3rem 0.75rem;
}
</style>
