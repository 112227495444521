<template>
  <div class="modal fade" tabindex="-10" id="modal_add_movimento">
    <div class="modal-dialog">
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Introduci Movimento</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold">Stagione*</label>
                    <SelectInput
                      :options="stagioni"
                      name="anno"
                      :value="anno"
                      placeholder="Seleziona"
                      @changeSelect="
                        setStagione($event);
                        getQuote(tipo, anno);
                        spliceQuote();
                      "
                    />
                  </div>
                  <div class="col-md-9">
                    <label class="fw-bold">Tipo movimento*</label>
                    <SelectInput
                      :options="quote_tipologie_man"
                      name="tipo"
                      :value="tipo"
                      placeholder="Seleziona"
                      @changeSelect="
                        setTipo($event);
                        getQuote(tipo, anno);
                        spliceQuote();
                      "
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold">Quota*</label>
                    <Field
                      name="id_quota"
                      as="select"
                      class="form-select"
                      v-model="id_quota"
                      @change="setDettagliQuota($event)"
                    >
                      <option value="0" selected>Seleziona</option>
                      <option
                        v-for="quota in quote"
                        :key="quota.id_quota"
                        :value="quota.id_quota"
                        :id="quota.importo_quota"
                        :data-mod="quota.modificabile"
                      >
                        {{ quota.descrizione }}
                      </option>
                    </Field>
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="id_quota"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-4">
                    <label class="fw-bold">Quantità</label>
                    <Field
                      name="quantita"
                      type="text"
                      :value="quantita ? quantita : 0"
                      class="form-control"
                      @change="
                        setQuantita($event.target.value);
                        setAvviso();
                      "
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="quantita"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold">Importo unitario*</label>
                    <div v-if="importo_mod == 1">
                      <div class="input-group mb-3 input-group-sm">
                        <Field
                          name="importo"
                          type="text"
                          v-model="importo"
                          class="form-control"
                          @change="
                            setImporto($event.target.value);
                            setAvviso();
                          "
                        />
                        <span class="input-group-text">€</span>
                      </div>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="importo"
                      />
                    </div>
                    <div v-else>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="importo.toFixed(2) + ' €'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="">
                    <label v-if="tipoAvviso == 1"
                      ><small
                        >(<b>Attenzione:</b> inserire importi e quantità
                        maggiori di 0)</small
                      ></label
                    >
                    <label v-if="tipoAvviso == 2"
                      ><small
                        >(<b>Attenzione:</b> verrà creato un movimento da
                        {{ somma.toFixed(2) }} €)</small
                      ></label
                    >
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold">Descrizione*</label>
                    <Field
                      type="text"
                      as="textarea"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      name="descrizione"
                      v-model="descrizione"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="descrizione"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalMovimento"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Registra
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import SelectInput from "../../utility/inputs/SelectInput.vue";

import { useStore } from "vuex";

import { addMovimentoManuale } from "@/requests/economato";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "movimenti-societa-add",
  emits: ["refreshList", "setSpinnerView"],
  props: ["societa"],
  components: {
    SelectInput,
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const quote_tipologie_man = computed(() =>
      store.getters.getStateFromName("quote_tipologie_man")
    );
    const quote = computed(() => store.getters.getStateFromName("quote"));

    const keys = ref("qtm");
    if (!quote_tipologie_man.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const alertCampiObb = ref(false);
    const anno = ref(null);
    anno.value = new Date().getFullYear();
    const tipo = ref(null);
    const importo = ref(null);
    const quantita = ref(1);
    const descrizione = ref("");
    const tipoAvviso = ref(1);
    const somma = ref(0);
    const id_quota = ref(0);
    const importo_mod = ref(1);

    const setStagione = (idStagione) => {
      anno.value = parseInt(idStagione);
    };

    const setTipo = (id) => {
      spliceQuote();
      id_quota.value = null;
      tipo.value = parseInt(id);
      importo.value = 0;
    };

    const getQuote = (id_tipologia, stagione) => {
      store.dispatch("setStoreData", {
        keys: { id_tipologia: id_tipologia, stagione: stagione },
        apiLink: globalApi.LOOKUP_ECONOMATO_QUOTE,
      });
    };

    const spliceQuote = () => {
      store.commit("spliceArrayFromName", "quote");
    };

    const setQuantita = (e) => {
      if (!isNaN(e)) {
        quantita.value = parseInt(e);
      }
    };

    const setImporto = (e) => {
      importo.value = e;
    };

    const setAvviso = () => {
      tipoAvviso.value = 1;
      if (String(quantita.value) != "" && String(importo.value) != "") {
        tipoAvviso.value = 1;
      }
      if (!isNaN(quantita.value) && !isNaN(importo.value)) {
        if (parseInt(quantita.value) > 0 && parseFloat(importo.value) > 0) {
          somma.value = quantita.value * importo.value;
          tipoAvviso.value = 2;
        }
      }
    };

    const setDettagliQuota = (e) => {
      if (
        e.target.selectedOptions[0]._value != null &&
        String(e.target.selectedOptions[0]._value) != ""
      ) {
        id_quota.value = e.target.selectedOptions[0]._value;
        if (parseInt(id_quota.value) > 0) {
          importo.value = parseFloat(e.target.selectedOptions[0].id);
          importo_mod.value = parseInt(e.target.selectedOptions[0].dataset.mod);
          descrizione.value = e.target.selectedOptions[0].text;
        } else {
          importo.value = null;
          importo_mod.value = 1;
          descrizione.value = "";
        }
      } else {
        id_quota.value = 0;
        importo.value = null;
        importo_mod.value = 1;
        descrizione.value = "";
      }
      setAvviso();
    };

    const schema = yup.object().shape({
      anno: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      tipo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_quota: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .moreThan(0, "Obbligatorio")
        .nullable(),
      quantita: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .moreThan(0, "Obbligatorio")
        .nullable(),
      importo: yup.number().when("id_quota", {
        is: (id_quota) => parseInt(importo_mod.value) === 1,
        then: yup
          .number()
          .required("Obbligatorio")
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(),
        otherwise: yup.number().nullable(),
      }),
      descrizione: yup.string().required("Obbligatorio"),
    });

    const disableButton = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      Swal.fire({
        html:
          "Per confermare il movimento con importo di EUR " +
          String(Number(somma.value).toFixed(2)) +
          ", si prega di cliccare su <b>Conferma</b>.<br />Per annullare l'operazione cliccare su <b>Annulla</b>.",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          disableButton.value = true;
          emit("setSpinnerView", true);
          values.id_societa = props.societa.id;
          values.numero_Quote = quantita.value;
          const responseAdd = addMovimentoManuale(values);
          responseAdd.then((value) => {
            if (parseInt(value.data.statusCode) == 200) {
              Swal.fire({
                html: "Operazione completata",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                },
              });
              emit("refreshList");
              resetField();
              document.getElementById("closeModalMovimento").click();
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            disableButton.value = false;
            emit("setSpinnerView", false);
          });
        }
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      anno.value = new Date().getFullYear();
      tipo.value = null;
      importo.value = null;
      quantita.value = 1;
      descrizione.value = "";
      tipoAvviso.value = 1;
      somma.value = 0;
      id_quota.value = 0;
      importo_mod.value = 1;
      spliceQuote();
    };

    return {
      onSubmit,
      onInvalidSubmit,
      schema,
      stagioni: computed(() => store.getters.stagioni),
      setStagione,
      tipo,
      setTipo,
      quote_tipologie_man,
      alertCampiObb,
      resetField,
      anno,
      disableButton,
      quantita,
      descrizione,
      getQuote,
      tipoAvviso,
      quote,
      setQuantita,
      setImporto,
      setAvviso,
      somma,
      setDettagliQuota,
      id_quota,
      importo,
      importo_mod,
      spliceQuote,
    };
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.3rem 0.75rem;
}
</style>
