import { useStore } from "vuex";
import { computed } from "vue";

export default function useRemoveElement() {
  const store = useStore();
  const comandi_enabled = computed(() => store.getters.comandi_enabled);
  comandi_enabled.value.forEach((element) => {
    const elementToEnable = document.getElementById(element.nc);
    if (elementToEnable)
      elementToEnable.parentNode.removeChild(elementToEnable);
  });
}
